<template>
  <div>
    <div class="user-title">
      <h2>修改登录密码</h2>
    </div>
    <div class="security-warp">
      <form class="user-form user-form-safe">
        <div class="form-row">
          <div class="form-label">原密码：</div>
          <div class="form-value">
            <input class="form-input" v-model="oldPassword" type="password">
          </div>
        </div>
        <div class="form-row">
          <div class="form-label">新密码：</div>
          <div class="form-value">
            <input class="form-input authCode txt-4" v-model="password" type="password">
          </div>
        </div>
        <div class="form-row">
          <div class="form-label">确认密码：</div>
          <div class="form-value">
            <input class="form-input authCode txt-4" v-model="confirmPassword" type="password">
          </div>
        </div>
        <div class="form-row">
          <div class="form-label">验证码：</div>
          <div class="form-value">
            <input class="form-input authCode txt-4" v-model="code" type="text">
            <img  alt="点击切换验证码" title="点击切换验证码" :src="imgUrl" @click="changeImg">
          </div>
        </div>
        <div class="form-row">
          <div class="form-label">&nbsp;</div>
          <div class="form-value form-value-btn">
            <input class="form-btn form-btn-line" value="确定" @click="changePassword">
          </div>
        </div>
      </form>
    </div>
  </div>
</template>

<script>

import {updatePassword} from "@/api/user";

export default {
  name: "ChangePassword",
  data() {
    return {
      oldPassword : '',
      password : '',
      confirmPassword : '',
      code : '',
      timestamp : '',
      imgUrl : '',
    }
  },
  created() {
    this.changeImg()
  },
  methods:{
    changeImg() {
      let timestamp = Math.random()
      this.timestamp = timestamp
      this.imgUrl = `/api/Common/ImageCode/${timestamp}`
    },
    changePassword(){
      if(this.oldPassword == ''){
        this.$message({
          message: '原密码不能为空！',
          type: 'warning'
        });
        return;
      }
      if(this.password == ''){
        this.$message({
          message: '新密码不能为空！',
          type: 'warning'
        });
        return;
      }
      if(this.password != this.confirmPassword){
        this.$message({
          message: '新密码和确认密码不一致！',
          type: 'warning'
        });
        return;
      }
      if(this.code == ''){
        this.$message({
          message: '验证码不能为空！',
          type: 'warning'
        });
        return;
      }

      let data = {}
      data.oldPassword = this.oldPassword;
      data.password = this.password;
      data.code = this.code;
      data.timestamp = this.timestamp;
      updatePassword(data).then(res => {
       if(res.code == 200){
          this.$message({
            message: res.msg,
            type: 'success'
          });
         this.$router.push('/login')
        }
      })
    }
  }
}
</script>

<style scoped>

</style>
